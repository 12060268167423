import React, { useState } from 'react'
import TextareaAutosize from 'react-textarea-autosize'
import { compose } from 'redux'
import { connect, useSelector } from 'react-redux'
import { Button } from 'reactstrap'
import { utcNow, utcToLocal } from 'app/common/utils/helpers'
import { connectResource } from 'app/common/utils/resource'
import { updateOrder as updateOrderFunc } from 'app/store/reducers/order/orderSlice'
import { selectAllOrderData } from 'app/store/reducers/order/orderSelectors'
import { USER_TYPES } from 'app/common/constants/userTypes'

function onKeyPress(e, onSubmit) {
  if (e.key === 'Enter') {
    e.preventDefault()
    onSubmit(null)
  }
}

const CurrentVendorMessage = ({ message }) => {
  return (
    <li style={{
      marginBottom: '8px',
      padding: '8px',
      border: '1px solid #ccc',
      borderRadius: '5px',
      listStyle: 'none',
      display: 'flex',
      gap: '12px',
      alignItems: 'center',
    }}>
      <div>{message.message}</div>
      <div style={{ color: '#666', fontSize: '0.9em', marginLeft: 'auto' }}>
        {message.sender.fullname} • {utcToLocal(message.created_date).format('MM/DD/YYYY hh:mm a')}
      </div>
    </li>
  )
}

const AmpNotes = ({
  addVendorNote,
  updateOrder,
  currentUserID,
  currentUserInfo,
}) => {
  const [ampNotes, setAmpNotes] = useState('')
  const orderData = useSelector(selectAllOrderData)
  const currentAmpNotes = (orderData.current_vendor_messages || []).filter(m => m.is_amc)
  const hasAmpNotes = currentAmpNotes.length > 0

  const onSubmit = () => {
    // We will never be in a unified conversation here, since for AMP we are always sending to the vendor
    const orderId = orderData.id
    const message = {
      message: ampNotes,
      order_id: orderId,
      sender: {
        ...currentUserInfo,
        id: currentUserID,
        type: USER_TYPES.AMC,
      },
      sent_time: utcNow(),
      recipient: 'vendor',
    }
    addVendorNote.put({
      ...message,
      conversation_type: 'current_vendor_messages',
    }).then(() => {
      updateOrder({
        orderID: orderId,
        update: {
          current_vendor_messages: [...currentAmpNotes, {
            ...message,
            is_amc: true,
            sender_type: USER_TYPES.AMC,
            created_date: message.sent_time,
          }],
        },
      })
      setAmpNotes('')
    })
  }

  return (
    <div>
      {hasAmpNotes && (
        <section className="my-3">
          <p style={{ fontWeight: 'bold' }}>Current AMP Notes</p>
          <ul style={{ padding: 0 }}>
            {currentAmpNotes.map(m => (
              <CurrentVendorMessage key={m.id} message={m} />
            ))}
          </ul>
        </section>
      )}
      <section className="input-group my-3 send-message-input">
        <TextareaAutosize
          className="form-control mr-2"
          placeholder=""
          title="AMP Notes"
          value={ampNotes}
          onChange={(e) => setAmpNotes(e.target.value)}
          onKeyPress={(e) => onKeyPress(e, onSubmit)}
        />
        <Button onClick={onSubmit} disabled={!ampNotes}>
          Create AMP Note
        </Button>
      </section>
    </div>
  )
}

function mapStateToProps(state) {
  return {
    currentUserID: state.resource.user.data.id,
    currentUserInfo: {
      firstname: state.resource.user.data.firstname,
      lastname: state.resource.user.data.lastname,
      username: state.resource.user.data.username,
      fullname: `${state.resource.user.data.firstname} ${state.resource.user.data.lastname}`,
      lender_name: state.resource.user.data.lender.name,
    },
  }
}

export default compose(
  connectResource({
    namespace: 'messageAdd',
    endpoint: 'order/message',
    prefetch: false,
    navigateAfterSubmit: false,
    excludeID: true,
    apiVersion: 2,
  }),
  connectResource({
    namespace: 'addVendorNote',
    endpoint: 'order/current-vendor-message',
    prefetch: false,
    navigateAfterSubmit: false,
    excludeID: true,
    apiVersion: 2,
  }),
  connect(mapStateToProps, { updateOrder: updateOrderFunc }),
)(AmpNotes)
