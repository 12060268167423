import React, { Component, Fragment } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import {
  ModalBody,
  Modal,
} from 'reactstrap'
import ModalInformation from '../../common/modals/ModalInformation'
import { connectResource } from '../../common/utils/resource'
import { selectHasPrimaryContact } from '../../store/reducers/fullSelectedLoan/fullSelectedLoanSelectors'
import ModalCreateOrder from './ModalCreateOrder'
import moment from 'moment'
import { ELLIE_MAE_PRODUCT_REQUEST_TYPE } from '../../common/constants/orders'
import {
  allStatesShort,
  states,
  convertToUTCDate,
  attentionRequiredIsAllocation,
  alwaysDisableVendorSelection,
  overrideVendorSelection,
  checkPermission,
  ellieMaeRequest,
} from '../../common/utils/helpers'

import Loader from '../../common/layout/components/Loader'
import { withRouter } from 'react-router-dom'
import host from '@elliemae/em-ssf-guest'
import { datadogRum } from '@datadog/browser-rum'
import { cloneDeep } from 'lodash'
import { withLDConsumer } from 'launchdarkly-react-client-sdk'

const initial_additional_fee = { key: 0, description: null, amount: null }

// applied filters are updated on APPLY button handler
const defaultAppliedFilters = {
  radiusSearchSelected: false,
  geoFiltersApplied: false,
  specializationFiltersApplied: false,
  appraisalStatsApplied: false,
  radiusDistance: 0,
  specialization_fha: false,
  licenseCertified: false,
  licenseCertifiedGeneral: false,
  licenseCertifiedResidential: false,
  turnTimeSelected: false,
  turnTimeLower: 0,
  turnTimeUpper: 0,
  rejectRateSelected: false,
  rejectRateLower: 0,
  rejectRateUpper: 0,
  onTimeRateSelected: false,
  onTimeRateLower: 0,
  onTimeRateUpper: 0,
  mapSearchSelected: false,
  panelSource: 'ourPanel',
  appraiserCoverageApplied: true,
  zipCodesSelected: [],
  hideDeclinedAppraisersSelected: false,
}

// selected filters reflects current state of filter -- toggled on or off -- has not necessarily been applied yet
const defaultSelectedFilters = {
  radiusSearchSelected: false,
  radiusDistance: 0,
  specialization_fha: false,
  licenseCertified: false,
  licenseCertifiedGeneral: false,
  licenseCertifiedResidential: false,
  turnTimeSelected: false,
  turnTimeLower: 0,
  turnTimeUpper: 0,
  rejectRateSelected: false,
  rejectRateLower: 0,
  rejectRateUpper: 0,
  onTimeRateSelected: false,
  onTimeRateLower: 0,
  onTimeRateUpper: 0,
  mapSearchSelected: false,
  panelSource: 'ourPanel',
  appraiserCoverageApplied: true,
  zipCodesSelected: [],
  hideDeclinedAppraisersSelected: false,
}

class ModalCreateOrderContainer extends Component {
  state = {
    activeTab:
      this.props.is_reassign_order &&
      this.props.orderData.job_type === 'manually'
        ? '2'
        : '1',
    additional_fees: [initial_additional_fee],
    additionalProductFees: [],
    hasAdditionalFees: false,
    keyCounter: 0,
    range: null,
    filteringAppraisers: false,
    loadingProducts: false,
    due_date: moment(),
    appraiser_due_date: moment(),
    product_amount: 0,
    reggora_fee: 0,
    products: [],
    initialProducts: [],
    selected_products: this.props.selectedLoan.auto_products
      ? this.props.selectedLoan && this.props.selectedLoan.auto_products
      : [null],
    selected_property: 'keep',
    consumer_payment_style: this.props.consumer_payment_style
      ? this.props.consumer_payment_style
      : 'ordering',
    priority:
      this.props.selectedLoan && this.props.selectedLoan.rush
        ? 'Rush'
        : 'Normal',
    job_type: this.props.default_allocation_mode || 'automatically',
    original_job_type: this.props.default_allocation_mode || 'automatically',
    order_request_method:
      (this.props.orderData && this.props.orderData.order_request_method) ||
      this.props.default_order_request_method ||
      'individually',
    appraiser_acceptance_method: 'first_come_first_serve',
    offer_parameters: [],
    auto_accept_conditions: {
      combinator: 'and',
      due_date: moment(),
    },
    outOfAppraisersAutomatic: false,
    outOfAppraisersManual: false,
    valid: false,
    editing: false,
    integrationMode: false,
    integration_products_loading: false,
    mapOpen: false,
    availableAppraisers: this.props?.listAppraisers?.data ?? [],
    selectedAppraisers: [],
    already_assigned_followup_once: false,
    searchInputText: '',
    selectedFilters: defaultSelectedFilters,
    appliedFilters: defaultAppliedFilters,
    popoversVisible: {
      priorityPopover: false,
      jobTypePopover: false,
      geoFilterPopover: false,
      specializationFilterPopover: false,
      appraisalPanelSourcePopover: false,
      appraiserCoveragePopover: false,
      appraisalStatsPopover: false,
      orderRequestMethodPopover: false,
    },
    pdfFile: null,
    xmlFile: null,
    selectedAppraisersAscendingOrder: {
      firm_name: true,
      proximity: true,
      on_time_percentage: true,
      orders_requiring_revision_percent: true,
    },
    availableAppraisersAscendingOrder: {
      firm_name: true,
      proximity: true,
      on_time_percentage: true,
      orders_requiring_revision_percent: true,
    },
    isOrderCreationFailedAlertShowing: false,
    isSetSelectedLoanParams: false,
    maxCascadeAssignments:
      this.props.max_cascade_assignments,
    maxActiveNonPendingAssignments:
      this.props.max_cascade_non_pending_active_assignments,
  }

  determineProducts = (fullList, selectedList, isEditingAmount) => {
    // Check if there is an integration and therefore we need to update the selected products
    const { use_integration_fee } = this.props

    const newSelectedProducts = []
    let productHasIntegrationFee = false
    let product_amount = 0
    const clonedFullList = cloneDeep(fullList)

    selectedList.forEach((selected_product) => {
      if (clonedFullList) {
        clonedFullList.forEach((product) => {
          if (selected_product !== null && product.id === selected_product.id) {
            // check if product has integration fee
            if (product.external_fee && use_integration_fee) {
              productHasIntegrationFee = true
              selected_product.amount = product.amount
              product_amount = product.external_fee
              newSelectedProducts.push({
                ...product,
                amount: selected_product.amount,
              })
            } else {
              // check if product amount has been edited, original_amount used in geoOverride check below
              if (product.amount !== selected_product.amount) {
                product.original_amount = product.amount
                product.amount = selected_product.amount
                product.manual_fee_override_exists =
                  selected_product.manual_fee_override_exists
              }
              newSelectedProducts.push(product)
            }
          }
        })
      }
    })
    if (newSelectedProducts.length === 0) {
      newSelectedProducts.push(null)
    }

    if (productHasIntegrationFee && use_integration_fee) {
      // CASE: USE INTEGRATION FEE
      this.setState({ selected_products: newSelectedProducts, product_amount })
    } else {
      // CASE: DON'T USE INTEGRATION FEE
      // This is either because its not an integration order, OR
      // it is an integration order but the lender does not want to use the integration fee
      // this is based on a lender setting
      let totalFee = 0
      let reggoraFee = 0

      const state = this.getPropertyState()
      const zip = this.getPropertyZip()
      const product_ids = []
      newSelectedProducts
        .filter((product) => product)
        .forEach((product) => {
          if (!isEditingAmount && !product.manual_fee_override_exists) {
            product_ids.push(product.id)
          } else {
            totalFee += parseFloat(product.amount)
          }

          if (product.reggora_fee) {
            reggoraFee += parseFloat(product.reggora_fee)
          }
        })
      if (product_ids && product_ids.length) {
        // Calls endpoint and sends list of product_ids. Endpoint returns override fees for all of them
        this.getPricesFromProducts(product_ids, state, zip).then((result) => {
          // Loop through object keys to set product amount if matches and sum up totalFee amount
          Object.keys(result).forEach((key) => {
            const selectedProduct = newSelectedProducts.find(
              (product) => product && product.id === key,
            )
            if (selectedProduct) {
              const selectedFullListProduct = clonedFullList.find(
                (fullListProduct) => fullListProduct.id === selectedProduct.id,
              )
              if (
                selectedFullListProduct &&
                selectedFullListProduct.original_amount &&
                (selectedFullListProduct.original_amount ===
                  result[key].geoOverrideAmount ||
                  selectedFullListProduct.amount ===
                    result[key].geoOverrideAmount ||
                  selectedFullListProduct.amount !==
                    selectedFullListProduct.original_amount)
              ) {
                totalFee += parseFloat(selectedFullListProduct.amount)
              } else {
                selectedProduct.amount = result[key].geoOverrideAmount
                totalFee += parseFloat(selectedProduct.amount)
              }
            }
          })
          this.setState(
            {
              selected_products: newSelectedProducts,
              product_amount: totalFee,
              reggora_fee: reggoraFee,
            },
            () => {
              this.setDefaultDueDate()
            },
          )
        })
      } else {
        this.setState(
          {
            selected_products: newSelectedProducts,
            product_amount: totalFee,
            reggora_fee: reggoraFee,
          },
          () => {
            this.setDefaultDueDate()
          },
        )
      }
    }

    if (clonedFullList && clonedFullList.length > 0) {
      return clonedFullList.filter(
        (product) =>
          !selectedList
            .filter((p) => p)
            .find((selected) => product.id === selected.id),
      )
    }

    return clonedFullList || []
  }

  componentDidMount() {
    const {
      default_proximity_allocation = 0,
      use_default_proximity_allocation,
      listAppraisers,
      selectedLoan,
      consumerPaymentOptions,
      is_amc_lender,
      reviewAppraiserOptions,
      orderData,
      products,
      getAllocationMode,
      flags,
    } = this.props

    const radiusDistance = default_proximity_allocation.toString()
    this.setState({
      filteringAppraisers: true,
      loadingProducts: true,
      // set the default proximity settings
      selectedFilters: {
        ...defaultSelectedFilters,
        radiusSearchSelected: use_default_proximity_allocation,
        radiusDistance,
      },
      appliedFilters: {
        ...defaultAppliedFilters,
        radiusSearchSelected: use_default_proximity_allocation,
        radiusDistance,
        geoFiltersApplied: use_default_proximity_allocation,
      },
    })

    const filterObject = this.buildFilterObject(true)

    listAppraisers.filter(filterObject).then((response) => {
      this.sortAppraisers(
        '-proximity',
        'availableAppraisers',
        response.results,
      )
    })
    if (flags && flags.loanPageOptimization && !orderData) {
      getAllocationMode.filter({ loan_id: selectedLoan?.id })
        .then((result) => {
          this.setState({
            job_type: result?.default_allocation_mode,
            original_job_type: result?.default_allocation_mode,
          })
        })
    }

    // Call the handleLoanChange method if connectResource has the data
    if (selectedLoan && products && products.data) {
      this.setState({
        isSetSelectedLoanParams: true,
      })
      this.handleLoanChange(selectedLoan)
    }

    if (orderData) {
      const productPermissions = this.setUpProductPermissions()
      this.setState({
        productPermissions,
        editing: false,
        loadingProducts: false,
      })
    } else {
      const productPermissions = this.setUpProductPermissions()
      this.setState({
        productPermissions,
        loadingProducts: false,
      })
    }

    if (selectedLoan) {
      var branch_ids = JSON.stringify([selectedLoan.branch_id])
      consumerPaymentOptions
        .filter({ branch_ids: branch_ids })
        .then((response) => {
          this.setState({
            consumerPaymentOptions: response[0],
          })
        })
    }

    if (!orderData) {
      this.setDefaultDueDate()
    }
    // We're in an integration mode and need to set that when the modal is created
    if (
      orderData &&
      (orderData.appraisal_scope_appraisal_id || orderData.valutrac_id)
    ) {
      this.integrationUpdate()
    }

    if (is_amc_lender) {
      reviewAppraiserOptions.filter({})
    }

    if (products) {
      // fetch products list with query params
      products.filter({ limit: 0, loan_id: selectedLoan.id })
    }

    document.addEventListener('mouseup', this.handleClick)
  }

  componentWillUnmount() {
    document.removeEventListener('mouseup', this.handleClick)
  }

  componentDidUpdate(prevProps, prevState) {
    // only update chart if the data has changed
    const { products, selectedLoan } = this.props


    if (prevState.job_type !== 'va' && this.state.job_type === 'va') {
      this.setState({
        priority: 'Normal',
      })
    }

    if (prevProps.products.isLoading && !products.isLoading) {
      const productPermissions = this.setUpProductPermissions()
      this.setState({
        productPermissions,
        products: this.determineProducts(
          products.data,
          this.state.selected_products,
        ),
      })

      if (!this.state.isSetSelectedLoanParams) {
        this.handleLoanChange(selectedLoan)
      }

      // Update additional product fees
      this.setState({
        additionalProductFees: this.getAdditionalProductFees(this.state.selected_products),
      })
    }

    let isIntegrationSwitchingToRush = false

    if (this.state.integrationMode && this.state.priority === 'Rush') {
      isIntegrationSwitchingToRush = true
    }

    if (
      prevState.priority !== this.state.priority &&
      !isIntegrationSwitchingToRush
    ) {
      // Do not need to call updateRushFees method if the AMP user edits the order
      // It overwrites the additional fees if a lender is using lender level rush fee setting
      if (!this.props.is_amc_lender) {
        this.updateRushFees()
      }
      this.setDefaultDueDate()
    }

    // below code is used to determine color of the filter buttons based on whether or not filters are selected
    // check if specialization filters are selected
    if (
      prevState.appliedFilters.radiusSearchSelected !==
        this.state.appliedFilters.radiusSearchSelected ||
      prevState.appliedFilters.specialization_fha !==
        this.state.appliedFilters.specialization_fha ||
      prevState.appliedFilters.licenseCertified !==
        this.state.appliedFilters.licenseCertified ||
      prevState.appliedFilters.licenseCertifiedGeneral !==
        this.state.appliedFilters.licenseCertifiedGeneral ||
      prevState.appliedFilters.licenseCertifiedResidential !==
        this.state.appliedFilters.licenseCertifiedResidential ||
      prevState.appliedFilters.turnTimeSelected !==
        this.state.appliedFilters.turnTimeSelected ||
      prevState.appliedFilters.rejectRateSelected !==
        this.state.appliedFilters.rejectRateSelected ||
      prevState.appliedFilters.onTimeRateSelected !==
        this.state.appliedFilters.onTimeRateSelected ||
      prevState.appliedFilters.hideDeclinedAppraisersSelected !==
        this.state.appliedFilters.hideDeclinedAppraisersSelected
    ) {
      const {
        radiusSearchSelected,
        specialization_fha,
        licenseCertified,
        licenseCertifiedGeneral,
        licenseCertifiedResidential,
        turnTimeSelected,
        rejectRateSelected,
        onTimeRateSelected,
      } = this.state.appliedFilters

      let geoFiltersApplied = false
      let specializationFiltersApplied = false
      let appraisalStatsApplied = false

      if (radiusSearchSelected) {
        geoFiltersApplied = true
      }

      if (
        specialization_fha ||
        licenseCertified ||
        licenseCertifiedGeneral ||
        licenseCertifiedResidential
      ) {
        specializationFiltersApplied = true
      }

      if (turnTimeSelected || rejectRateSelected || onTimeRateSelected) {
        appraisalStatsApplied = true
      }

      this.setState({
        appliedFilters: {
          ...this.state.appliedFilters,
          geoFiltersApplied,
          specializationFiltersApplied,
          appraisalStatsApplied,
        },
      })
    }
  }

  updateRushFees = () => {
    const { use_default_rush_fee, default_rush_fee } = this.props
    let { keyCounter, additional_fees } = this.state
    let containsProductRushFee = false
    let hasAdditionalFees = false
    let additionalFees

    // if Normal Priority is selected, we need to make sure additional_fees do not contain any rush fees
    // also if there are no remaining additional fees, we need to reinitialize additional_fees
    if (this.state.priority === 'Normal') {
      additionalFees = additional_fees.filter(
        (fee) => !fee.isProductRushFee && !fee.isDefaultRushFee,
      )
      if (additionalFees.length === 0) {
        additionalFees = [initial_additional_fee]
        hasAdditionalFees = false
      }
    } else {
      // else if Rush Priority is selected:
      // get all additional fees that are not rush fees
      additionalFees = additional_fees.filter(
        (fee) => !fee.isProductRushFee && !fee.isDefaultRushFee,
      )

      // check each product for rush fee and add to additional fees if needed
      this.state.selected_products.forEach((product) => {
        if (
          product &&
          product.using_default_rush_fee &&
          product.default_rush_fee
        ) {
          keyCounter++
          containsProductRushFee = true
          additionalFees.push({
            amount: product.default_rush_fee,
            description: 'Product Rush Fee',
            key: keyCounter,
            productId: product.id,
            isProductRushFee: true,
          })
        }
      })

      // if none of the additional fees are rush fees, then we need to check if the global rush fee is enabled, and if so add that to additional fees
      if (containsProductRushFee === false) {
        if (use_default_rush_fee && default_rush_fee) {
          keyCounter++
          additionalFees.push({
            amount: default_rush_fee,
            description: 'Rush Fee',
            key: keyCounter,
            isDefaultRushFee: true,
          })
        }
      }
    }

    // remove initial_additional_fee if necessary
    if (
      additionalFees &&
      additionalFees[0] &&
      additionalFees[0].description === null &&
      additionalFees.length > 1
    ) {
      additionalFees = additionalFees.filter(
        (fee) => fee && fee.description !== null,
      )
    }

    if (additionalFees.length > 0 && additionalFees[0].description !== null) {
      hasAdditionalFees = true
    }

    // weird edge case for additional fees with amounts but no description
    // if any of the additional fees have a description of null, but an amount other than null, reset the amount to null
    additionalFees.forEach((additionalFee) => {
      if (additionalFee.description === null && additionalFee.amount) {
        // reset the amount to null
        additionalFee.amount = null
      }
    })

    this.setState(
      {
        additional_fees: additionalFees,
        hasAdditionalFees,
        keyCounter,
      },
      this.integrationUpdate,
    )
  }

  setUpProductPermissions = () => {
    const { products } = this.props
    if (products && products.data) {
      return products.data.reduce((obj, fee) => {
        obj[fee.id] = fee.permissions
        return obj
      }, {})
    }
  }

  // Sets the due date based on the products and geographic overrides
  setDefaultDueDate = () => {
    const { selected_products, editing, priority } = this.state
    const { selectedLoan, getDefaultDueDate, orderData } = this.props

    // If we have the edit modal open then kill the defaulting behavior because its
    // super confusing.
    if (editing) {
      return
    }

    // If we have order data getting passed in from props then we know we are in the edit or reassign flow, so we
    // dont want to calculate the order due date. This is a pretty nasty component to begin with, so this fix
    // isnt ideal, but its the best thing that we can do right now.
    if (orderData) {
      return
    }

    const product_ids = selected_products.filter((p) => p).map((p) => p.id)
    const state = this.getPropertyState()
    const zip = this.getPropertyZip()

    // get default due date from the backend logic
    getDefaultDueDate
      .filter({
        product_ids,
        state,
        zip,
        priority,
        loan_id: selectedLoan.id,
      })
      .then((results) => {
        const selectedDate = results.result ? moment(results.result) : moment()
        this.setState({ due_date: selectedDate })
      })
  }

  setupEditing = () => {
    const {
      orderData,
      listAppraisers,
      default_allocation_mode,
      default_order_request_method,
      integrationProducts,
      selectedLoan,
      products,
      is_amc_lender,
      is_reassign_order,
    } = this.props
    const { products: originalOrderProducts } = orderData
    const { review_appraiser_choice, original_job_type } = this.state
    let selectedAppraisers = []
    let fees = []
    let feeCount = 0
    let hasAdditionalFees = false
    let order_request_method = orderData.order_request_method
    const requested = orderData.requested
    requested?.forEach?.((request) => {
      let hasBeenMatched = false
      listAppraisers?.data?.forEach?.((a) => {
        if (a.id === request.id) {
          selectedAppraisers.push(a)
          hasBeenMatched = true
        }
      })

      // in case listAppraisers does not return a match
      if (!hasBeenMatched) {
        selectedAppraisers.push(request)
      }
    })
    if (orderData.additional_fees.length) {
      for (const f of orderData.additional_fees) {
        if (f.description && f.amount) {
          fees.push({ ...f, key: feeCount })
          feeCount++
          hasAdditionalFees = true
        }
      }
    } else {
      fees = [initial_additional_fee]
    }
    const defaultJobType = original_job_type || default_allocation_mode || 'automatically'

    const selected_products = [...orderData.products]
    let job_type = orderData.job_type || defaultJobType
    // if the order is an amc order, and the lender is not AMP, and the order is being reassigned
    // then we need to clear the selected appraisers to hide reggora network appraisers
    if (orderData &&
      orderData.job_type === 'amc' &&
      !is_amc_lender &&
      is_reassign_order
    ) {
      selectedAppraisers = []
    }

    // Force manually job type if the order is being reassigned
    // Reset the order request method to the default if the order is being reassigned
    if (is_reassign_order && !is_amc_lender) {
      order_request_method = default_order_request_method
      job_type = 'manually'
    }

    if (
      selectedAppraisers &&
      selectedAppraisers.length > 0 &&
      selectedAppraisers[0].is_integration &&
      integrationProducts
    ) {
      integrationProducts
        .filter({
          company_id: selectedAppraisers[0].company,
          loan_id: selectedLoan.id,
        })
        .then((refetchedIntegrationProduct) => {
          const integrationProductsData =
            refetchedIntegrationProduct.data || []
          this.setState({
            integrationMode: true,
            products: this.determineProducts(
              integrationProductsData,
              this.state.selected_products,
            ),
          })
        })
    } else if (is_amc_lender && orderData.client) {
      // if the lender is an amc we need to get the products from the client, not the lender.
      // The client is another lender that the amc lender"AMP" is working this order for.
      this.props.products
        .filter({
          lender: orderData.client.id,
        })
        .then((_) => {
          this.setState({
            products: this.determineProducts(
              this.props.products.data,
              selected_products,
            ),
          })
        })
    } else {
      this.setState({
        products: this.determineProducts(products.data, selected_products),
      })
    }

    const dueDate = orderData.lender_due_date || orderData.due_date
    const appraiserDueDate = orderData.due_date

    const additionalProductFees = this.getAdditionalProductFees(selected_products)

    this.setState({
      selectedAppraisers,
      consumer_payment_style: orderData.consumer_payment_style,
      outOfAppraisersAutomatic:
        attentionRequiredIsAllocation(
          orderData.lender_attention_required,
          orderData.lender_attention_required_reason,
        ) && orderData.job_type === 'automatically',
      outOfAppraisersManual:
        attentionRequiredIsAllocation(
          orderData.lender_attention_required,
          orderData.lender_attention_required_reason,
        ) && orderData.job_type === 'manually',
      show_edit_appraisers: orderData.can_change_requested_appraiser,
      additional_fees: fees,
      hasAdditionalFees,
      additionalProductFees,
      keyCounter: feeCount,
      original_job_type: job_type,
      job_type: job_type,
      selected_products,
      order_request_method: order_request_method,
      appraiser_acceptance_method: orderData.appraiser_acceptance_method,
      offer_parameters: orderData.offer_parameters,
      auto_accept_conditions: {
        ...this.state.auto_accept_conditions,
        ...orderData.auto_accept_conditions,
      },
      priority: orderData.priority,
      editing: true,
      review_appraiser_choice:
        review_appraiser_choice ||
        (orderData.review_appraiser && orderData.review_appraiser.id),
      due_date: dueDate ? moment(dueDate) : moment(),
      appraiser_due_date: appraiserDueDate ? moment(appraiserDueDate) : moment(),
      initialProducts: originalOrderProducts.map(({ id, amount }) => ({ id, amount })),
    })
  }

  getPropertyZip = (newSelectedProperty) => {
    const { orderData, selectedLoan } = this.props
    const { selected_property, editing } = this.state
    let zip
    if (editing) {
      if (orderData.manual_subject_property) {
        zip = orderData.manual_subject_property.property_address_zip
      } else if (orderData.subject_property) {
        zip = orderData.subject_property.property_address_zip
      } else if (orderData.loan_file) {
        zip = orderData.loan_file.subject_property_zip
      }
    } else {
      // creating a new order
      const selected = newSelectedProperty || selected_property
      if (!selected) {
        // just use loan file
        zip = selectedLoan.subject_property_zip
      } else if (selected === 'keep') {
        // keeping from loan file
        zip = selectedLoan.subject_property_zip
      }
    }
    return zip
  }

  getPropertyState = (newSelectedProperty) => {
    const { orderData, selectedLoan } = this.props
    const { selected_property, editing } = this.state
    let state
    if (editing) {
      if (orderData.manual_subject_property) {
        state = orderData.manual_subject_property.property_address_state
      } else if (orderData.subject_property) {
        state = orderData.subject_property.property_address_state
      } else if (selectedLoan) {
        state = selectedLoan.subject_property_state
      }
    } else {
      // creating a new order
      const selected = newSelectedProperty || selected_property
      if (!selected) {
        // just use loan file
        state = selectedLoan.subject_property_state
      } else if (selected === 'keep') {
        // keeping from loan file
        state = selectedLoan.subject_property_state
      }
    }
    // check if state is real state
    if (state) {
      if (allStatesShort.indexOf(state.toUpperCase()) === -1) {
        // we found a state yet it doesn't match the short codes. Let's see if it is a full state name.
        for (const stateObj of states) {
          if (stateObj.long.toLowerCase() === state.toLowerCase()) {
            state = stateObj.short
            break
          }
        }
      }
    }
    return state
  }

  getPricesFromProducts = async (
    product_ids,
    state,
    zip,
    switchedToBroadcast,
  ) => {
    const { selectedLoan, orderData, fetchOverrideData } = this.props
    const { order_request_method } = this.state
    let appraiser_id
    if (orderData && orderData.requested?.length) {
      appraiser_id = orderData.requested[0].company
    }
    if (product_ids && product_ids.length) {
      const results = await fetchOverrideData.filter({
        product_ids: product_ids,
        state: state,
        zip: zip,
        switchedToBroadcast: switchedToBroadcast,
        order_request_method: order_request_method,
        appraiser_id: appraiser_id,
        loan_id: selectedLoan.id,
      })
      return results.results
    }
  }

  // Fetch the override dates based on product_ids, state and zip
  getDateOverridesFromProducts = async (product_ids, state, zip) => {
    const { selectedLoan, fetchOverrideData } = this.props
    if (product_ids && product_ids.length) {
      const results = await fetchOverrideData.filter({
        product_ids: product_ids,
        state: state,
        zip: zip,
        loan_id: selectedLoan.id,
      })
      return results.results
    }
  }

  getActiveCompany = () => {
    const { selectedAppraisers } = this.state
    return selectedAppraisers[0]
  }

  updateFee = (isEditingAmount, updatedSelectedProducts, selected_appraiser_id) => {
    // Update Total Product Fee and Reggora Fee
    let totalFee = 0
    let reggoraFee = 0
    const product_ids = []

    const state = this.getPropertyState()
    const zip = this.getPropertyZip()
    updatedSelectedProducts
      .filter((product) => product)
      .forEach((product) => {
        // we want to get the correct product prices including potential geographic override prices
        // but we will skip this step if the user is manually changing the price on a product
        if (!isEditingAmount && !product.manual_fee_override_exists) {
          product_ids.push(product.id)
        } else {
          // CASE: product has manual fee override
          totalFee += parseFloat(product.amount)
        }

        if (product.reggora_fee) {
          reggoraFee += parseFloat(product.reggora_fee)
        }
      })
    if (product_ids.length) {
      this.getPricesFromProducts(product_ids, state, zip, false, selected_appraiser_id).then((result) => {
        Object.values(result).forEach((amount) => {
          const amountOverride = amount.geoOverrideAmount
          totalFee += parseFloat(amountOverride)
        })
        // Loop through object keys to set product amount if matches
        Object.keys(result).forEach((key) => {
          const selectedProduct = updatedSelectedProducts.find(
            (product) => product && product.id === key,
          )
          if (selectedProduct) {
            selectedProduct.amount = result[key].geoOverrideAmount
          }
        })
        this.setState({
          product_amount: totalFee,
          reggora_fee: reggoraFee,
        })
      })
    }
  }

  getAdditionalProductFees = (selectedProducts) => {
    // Dedupe additional product fees
    // If there are multiple selected products and multiple additional product fees with the same fee_type
    // We only want to apply one fee per fee_type - the most expensive fee
    const maxFees = {}

    selectedProducts.forEach(product => {
      product && product.additional_fees && product.additional_fees.forEach(fee => {
        if (fee.fee_from === 'borrower') {
          if (fee.fee_type in maxFees) {
            if (fee.amount > maxFees[fee.fee_type].amount) {
              maxFees[fee.fee_type] = {
                amount: fee.amount,
                description: fee.description,
              }
            }
          } else {
            maxFees[fee.fee_type] = {
              amount: fee.amount,
              description: fee.description,
            }
          }
        }
      })
    })

    const additionalProductFees = Object.keys(maxFees).map(fee_type => {
      return {
        amount: maxFees[fee_type].amount,
        description: maxFees[fee_type].description,
      }
    })
    return additionalProductFees
  }

  productSelected = (selectedProduct, key, isEditingAmount) => {
    const {
      default_allocation_mode,
      integrationProducts,
      products,
      orderData,
      listAppraisers,
    } = this.props
    let {
      selected_products,
      additional_fees,
      integrationMode,
      selectedAppraisers,
      hasAdditionalFees,
    } = this.state

    // Update Selected Products
    let updatedSelectedProducts = [...selected_products]

    if (selectedProduct) {
      updatedSelectedProducts[key] = selectedProduct
    }

    if (isEditingAmount) {
      updatedSelectedProducts[key].manual_fee_override_exists = true
    }

    // Update Total Product Fee and Reggora Fee
    let totalFee = 0
    let reggoraFee = 0
    const product_ids = []

    const state = this.getPropertyState()
    const zip = this.getPropertyZip()
    updatedSelectedProducts
      .filter((product) => product)
      .forEach((product) => {
        // we want to get the correct product prices including potential geographic override prices
        // but we will skip this step if the user is manually changing the price on a product
        if (!isEditingAmount && !product.manual_fee_override_exists) {
          product_ids.push(product.id)
        } else {
          // CASE: product has manual fee override
          totalFee += parseFloat(product.amount)
        }

        if (product.reggora_fee) {
          reggoraFee += parseFloat(product.reggora_fee)
        }
      })
    if (product_ids.length && product_ids.length) {
      this.getPricesFromProducts(product_ids, state, zip, false).then((result) => {
        Object.values(result).forEach((amount) => {
          const amountOverride = amount.geoOverrideAmount
          totalFee += parseFloat(amountOverride)
        })
        // Loop through object keys to set product amount if matches
        Object.keys(result).forEach((key) => {
          const selectedProduct = updatedSelectedProducts.find(
            (product) => product && product.id === key,
          )
          if (selectedProduct) {
            selectedProduct.amount = result[key].geoOverrideAmount
          }
        })
      })
    }

    // Handle scenario for which user switches between amc and non-amc products
    const oldJobType = this.state.job_type
    let integrationProduct = false

    updatedSelectedProducts.forEach((product) => {
      if (product && product.external_fee) {
        integrationProduct = true
      }
    })

    // some logic for changing the job type when switching products
    const newJobType = selectedProduct.job_type
    let jobType = oldJobType

    if (integrationProduct && integrationMode) {
      // handle integration orders
      this.integrationUpdate()
    } else if (key === 0) {
      // the first select lets them choose a product w/ different job type
      // so here we need to check if we need to update accordingly
      let resetAdditionalFees = false
      let resetToOneProduct = false

      let jobTypeHasSwitched = false

      // If we're only editing amount we do not want to swap job types
      if (
        isEditingAmount ||
        ((oldJobType === 'automatically' || oldJobType === 'maually') &&
          newJobType === 'lender_choice')
      ) {
        // this means they haven't actually changed jobs -- both product job types are 'lender_choice'
        jobTypeHasSwitched = false
      } else if (newJobType !== oldJobType) {
        jobTypeHasSwitched = true
      }

      if (jobTypeHasSwitched) {
        // they actually changed the job type
        jobType = newJobType
        if (newJobType === 'lender_choice') {
          // translate lender_choice to the order job_type
          jobType = default_allocation_mode || 'automatically'
        }

        // determine what else needs to change
        if (['va', 'review'].includes(newJobType)) {
          // if we're changing to one of these job types, reset everything
          resetAdditionalFees = true
          resetToOneProduct = true
        } else if (['va', 'review'].includes(oldJobType)) {
          // switching away from these job types we need to reset other products
          resetToOneProduct = true
        }
      }
      if (resetAdditionalFees) {
        // they can't have additional fees for this job type
        additional_fees = [initial_additional_fee]
        hasAdditionalFees = false
      }
      if (resetToOneProduct) {
        // if the job type switches, we have to remove extra products
        updatedSelectedProducts = [selectedProduct]
      }
    }

    const integrationProductsData =
      integrationProducts.data && integrationProducts.data.data
        ? integrationProducts.data.data
        : []
    this.setState(
      {
        selected_products: updatedSelectedProducts,
        products: integrationMode
          ? this.determineProducts(
            integrationProductsData,
            updatedSelectedProducts,
            isEditingAmount,
          )
          : this.determineProducts(
            products.data,
            updatedSelectedProducts,
            isEditingAmount,
          ),
        reggora_fee: reggoraFee,
        product_amount: totalFee,
        job_type: jobType,
        already_assigned_followup_once: false,
        hasAdditionalFees,
        additional_fees,
      },
      () => {
        // we want to recalculate the rush fees if the order is rush priority and the product(s) changed
        if (this.state.priority === 'Rush' && !isEditingAmount) {
          this.updateRushFees()
        }
      },
    )

    // we need to filter out the appraisers when a new product is selected,
    // but we can skip this next step if the user is just manually overriding the product price

    // Also only change up the currently selected requested appraiser if we are working in a state whenere the appraiser selector isnt going to be disabled.
    if (
      jobType !== 'review' &&
      !isEditingAmount &&
      (!orderData || orderData.can_change_requested_appraiser)
    ) {
      // We are filtering with the base-case but with the selected product
      const filterObject = this.buildFilterObject()
      const filterObjectWithProduct = {
        ...filterObject,
        products: updatedSelectedProducts.map(
          (product) => product && product.id,
        ),
      }
      // Filter out the available list appraisers and the selected appraisers when products are changed
      listAppraisers.filter(filterObjectWithProduct).then((listAppraisers) => {
        const filteredList = []
        selectedAppraisers.forEach((selectedAppraiser) => {
          listAppraisers.results.forEach((listAppraiser) => {
            if (listAppraiser.id === selectedAppraiser.id) {
              filteredList.push(listAppraiser)
            }
          })
        })
        this.setState({
          loadingAppraisers: false,
          selectedAppraisers: filteredList,
          availableAppraisers: listAppraisers.results,
        })
      })
    }

    // Update additional product fees if a new product was selected
    // We don't need to do this if the user is just editing the amount
    if (!isEditingAmount) {
      const additionalProductFees = this.getAdditionalProductFees(updatedSelectedProducts)

      this.setState({
        additionalProductFees,
      })
    }
  }

  addProduct = () => {
    this.setState({
      selected_products: [...this.state.selected_products, null],
    })
  }

  removeProduct = (key) => {
    const { integrationProducts, products, listAppraisers } = this.props
    let {
      product_amount,
      reggora_fee,
      integrationMode,
      job_type,
      selectedAppraisers,
    } = this.state

    // Update Selected Products
    let updatedSelectedProducts = []
    updatedSelectedProducts = [...this.state.selected_products]
    const oldProduct = updatedSelectedProducts.splice(key, 1)[0]

    // Update Total Product Fee and Reggora Fee
    if (oldProduct) {
      if (oldProduct.amount) {
        product_amount -= parseFloat(oldProduct.amount)
      }
      if (oldProduct.reggora_fee) {
        reggora_fee -= parseFloat(oldProduct.reggora_fee)
      }
    }

    const integrationProductsData =
      integrationProducts.data && integrationProducts.data.data
        ? integrationProducts.data.data
        : []
    this.setState({
      selected_products: updatedSelectedProducts,
      products: integrationMode
        ? this.determineProducts(
          integrationProductsData,
          updatedSelectedProducts,
          true,
        )
        : this.determineProducts(products.data, updatedSelectedProducts, true),
      product_amount,
      reggora_fee,
    })

    // Update additional product fees
    const additionalProductFees = this.getAdditionalProductFees(updatedSelectedProducts)

    this.setState({
      additionalProductFees,
    })

    if (job_type === 'review') {
      // review order so we have no reason to fetch the appraisers
      return
    }

    // Reload available appraisers when a product is removed
    const filterObject = this.buildFilterObject()
    const filterObjectWithProduct = {
      ...filterObject,
      products: updatedSelectedProducts.map((product) => product && product.id),
    }
    // Filter out the available list appraisers and the selected appraisers when products are changed
    listAppraisers.filter(filterObjectWithProduct).then((listAppraisers) => {
      const filteredList = []
      selectedAppraisers.forEach((selectedAppraiser) => {
        listAppraisers.results.forEach((listAppraiser) => {
          if (listAppraiser.id === selectedAppraiser.id) {
            filteredList.push(listAppraiser)
          }
        })
      })
      this.setState({
        loadingAppraisers: false,
        selectedAppraisers: filteredList,
        availableAppraisers: listAppraisers.results,
      })
    })
  }

  editProductAmount = (val, key) => {
    const products = [...this.state.selected_products]
    const product = { ...products[key] }
    product.amount = val

    const isEditingAmount = true

    this.productSelected(product, key, isEditingAmount)
  }

  onAdditionalFeeAdd = () => {
    let { keyCounter } = this.state
    keyCounter++
    this.setState({
      additional_fees: [
        ...this.state.additional_fees,
        {
          ...initial_additional_fee,
          key: keyCounter,
        },
      ],
      keyCounter,
    })
  }

  onAdditionalFeeRemove = (index) => {
    const { additional_fees } = this.state

    let additionalFeesAfterRemove = [
      ...additional_fees.slice(0, index),
      ...additional_fees.slice(index + 1),
    ]

    let hasAdditionalFees = true
    if (additionalFeesAfterRemove.length === 0) {
      hasAdditionalFees = false
      additionalFeesAfterRemove = [initial_additional_fee]
    }

    this.setState({
      additional_fees: additionalFeesAfterRemove,
      hasAdditionalFees,
    })
  }

  onAdditionalFeeChange = (key, fieldName, value) => {
    const { additional_fees } = this.state

    const updatedFees = additional_fees.map(fee => {
      if (fee.key === key) {
        return {
          ...fee,
          [fieldName]: value,
        }
      }
      return fee
    })

    this.setState({
      additional_fees: updatedFees,
    })
  }

  onOrderDetailChange = (field, value) => {
    const { orderData } = this.props
    const { original_job_type, selected_products } = this.state

    const orderFormUpdate = {}

    if (field === 'job_type' && orderData) {
      orderFormUpdate.outOfAppraisersAutomatic =
        attentionRequiredIsAllocation(
          orderData.lender_attention_required,
          orderData.lender_attention_required_reason,
        ) &&
        original_job_type === 'automatically' &&
        value === 'automatically'
      orderFormUpdate.outOfAppraisersManual =
        attentionRequiredIsAllocation(
          orderData.lender_attention_required,
          orderData.lender_attention_required_reason,
        ) &&
        value === 'manually' &&
        original_job_type === 'manually'
    } else if (field === 'due_date' && !value.isValid()) {
      value = moment()
    } else if (field === 'appraiser_due_date' && !value.isValid()) {
      value = moment()
    }

    let switchedToBroadcast = false
    let hasSelectedProducts = false

    if (field === 'order_request_method' && value === 'broadcast') {
      switchedToBroadcast = true
    }

    if (selected_products[0] !== null) {
      hasSelectedProducts = true
    }

    if (switchedToBroadcast && hasSelectedProducts) {
      const state = this.getPropertyState(value)
      const zip = this.getPropertyZip(value)
      const updatedProducts = [...selected_products]
      let product_amount = 0
      const product_ids = []
      updatedProducts.forEach((product) => {
        product_ids.push(product.id)
      })
      this.getPricesFromProducts(
        product_ids,
        state,
        zip,
        switchedToBroadcast,
      ).then((result) => {
        Object.values(result).forEach((amount) => {
          const amountOverride = amount.geoOverrideAmount
          product_amount += parseFloat(amountOverride)
        })
        orderFormUpdate.product_amount = product_amount
      })
    }

    if (field === 'selected_property') {
      // gotta recalculate the fee based on current state
      const state = this.getPropertyState(value)
      const zip = this.getPropertyZip(value)
      let product_amount = 0
      const product_ids = []
      selected_products
        .filter((product) => product)
        .forEach((product) => {
          product_ids.push(product.id)
        })
      this.getPricesFromProducts(product_ids, state, zip).then((result) => {
        Object.values(result).forEach((amount) => {
          const amountOverride = amount.geoOverrideAmount
          product_amount += parseFloat(amountOverride)
        })
        orderFormUpdate.product_amount = product_amount
      })
    }

    orderFormUpdate[field] = value

    this.setState(orderFormUpdate, this.integrationUpdate)
    if (
      value === 'automatically' ||
      (field === 'order_request_method' && value === 'individually')
    ) {
      this.setState({
        activeTab: '1',
      })
    }

    this.closePopovers()
  }

  onCombinatorChange = (combinator) =>
    this.setState((currentState) => {
      currentState.auto_accept_conditions.combinator = combinator
      return currentState
    })

  handleAutoAcceptInput = (value, name) => {
    const { auto_accept_conditions } = this.state
    auto_accept_conditions[name] = value
    this.setState({ auto_accept_conditions })
  }

  isMissingFields = () => {
    let isMissingFields = false
    const { hasAdditionalFees, additional_fees } = this.state
    if (hasAdditionalFees) {
      additional_fees.forEach((fee) => {
        if (!fee.amount || !fee.description) {
          isMissingFields = true
        }
      })
    }
    return isMissingFields
  }

  checkValidity = () => {
    let valid = false
    const { is_follow_up, is_amc_lender } = this.props
    const {
      due_date,
      appraiser_due_date,
      job_type,
      selected_products,
      selected_property,
      editing,
      order_request_method,
      appraiser_acceptance_method,
      offer_parameters,
      auto_accept_conditions,
      pdfFile,
      xmlFile,
      selectedAppraisers,
    } = this.state

    if (
      due_date &&
      due_date.isValid() &&
      job_type &&
      selected_products &&
      (selected_property || editing || is_follow_up)
    ) {
      if (job_type === 'manually' && selectedAppraisers.length === 0) {
        valid = false
      } else {
        valid = true
      }
    }
    if (this.state.selected_products[0] === null) {
      valid = false
    }
    if (this.state.selected_products.some((p) => !p)) {
      valid = false
    }

    if (is_amc_lender && appraiser_due_date && !appraiser_due_date.isValid()) {
      valid = false
    }

    if (this.isMissingFields()) {
      valid = false
    }

    if (order_request_method === 'broadcast') {
      if (appraiser_acceptance_method === 'bid') {
        if (offer_parameters.length === 0) {
          return false
        }
        if (offer_parameters.includes('fee')) {
          if (!('fee' in auto_accept_conditions)) {
            return false
          } else if (!auto_accept_conditions.fee) {
            return false
          } else if (!auto_accept_conditions.fee_modifier) {
            return false
          }
        }
      }
    }

    if (job_type === 'review' && xmlFile && !pdfFile) {
      // if adding submission, must include the pdf
      valid = false
    }

    return valid
  }

  handleLoanChange = (loan_file) => {
    const { products } = this.props

    // check if the selected loan is an fha loan. set the fha certification filter by default if it is
    if (loan_file.is_fha_loan) {
      this.setState(
        {
          selectedFilters: {
            ...this.state.selectedFilters,
            specialization_fha: true,
          },
          appliedFilters: {
            ...this.state.appliedFilters,
            specialization_fha: true,
            specializationFiltersApplied: true,
          },
        },
        this.applyFilterHandler(),
      )
    }

    let update = {}
    let selected_products = [null]
    if (loan_file.auto_products && loan_file.auto_products.length) {
      selected_products = [...loan_file.auto_products]
      let product_amount = 0
      let reggora_fee = 0
      let va

      const state = this.getPropertyState()
      const zip = this.getPropertyZip()
      const product_ids = []
      selected_products
        .filter((product) => product)
        .forEach((product) => {
          product_ids.push(product.id)

          reggora_fee += parseFloat(product.reggora_fee) || 0
          if (product.is_va_product || product.job_type === 'va') {
            va = true
          }
        })
      this.getPricesFromProducts(product_ids, state, zip).then((result) => {
        Object.values(result).forEach((amount) => {
          const amountOverride = amount.geoOverrideAmount
          product_amount += parseFloat(amountOverride)
        })
        update = { selected_products, product_amount, reggora_fee }

        if (va) {
          update.job_type = 'va'
        }
        update.products = this.determineProducts(
          products.data,
          selected_products,
        )

        this.setState(update)
      })
    } else {
      this.setState({
        products: products.data,
      })
    }
  }

  getUniqueRequestedAppraisers = (selectedAppraisers) => {
    const requested = []
    const requestedIds = new Set()

    selectedAppraisers.forEach((selectedAppraiser) => {
      let requestedId
      if (selectedAppraiser.request_type === 'company') {
        // get company id
        requestedId = selectedAppraiser.company
        if (!requestedIds.has(requestedId)) {
          requested.push({
            type: 'company',
            id: requestedId,
            panelist_id: selectedAppraiser.panelist_id,
          })
          requestedIds.add(requestedId)
        }
      } else if (selectedAppraiser.request_type === 'appraiser') {
        // get appraisal user id
        requestedId = selectedAppraiser.resource_id
        if (!requestedIds.has(requestedId)) {
          requested.push({
            type: 'appraiser',
            id: requestedId,
            panelist_id: selectedAppraiser.panelist_id,
          })
          requestedIds.add(requestedId)
        }
      }
    })

    return requested
  }

  // Handles onSubmit if is_reassign_order
  onReassignSubmit = async () => {
    const {
      selectedAppraisers,
      order_request_method,
      appraiser_acceptance_method,
      offer_parameters,
      auto_accept_conditions,
      job_type,
    } = this.state
    const { onReassignOrder, orderData } = this.props

    const requested = this.getUniqueRequestedAppraisers(selectedAppraisers)

    await onReassignOrder(requested, orderData.id, {
      job_type,
      order_request_method,
      appraiser_acceptance_method,
      offer_parameters,
      auto_accept_conditions,
    })
  }

  onEditSubmit = async () => {
    const {
      selectedAppraisers,
      show_edit_appraisers,
      additional_fees,
      due_date,
      appraiser_due_date,
      priority,
      job_type,
      selected_products,
      outOfAppraisersAutomatic,
      product_amount,
      review_appraiser_choice,
      consumer_payment_style,
      order_request_method,
      appraiser_acceptance_method,
      offer_parameters,
      pdfFile,
      xmlFile,
      auto_accept_conditions,
      initialProducts,
      maxCascadeAssignments,
      maxActiveNonPendingAssignments,
    } = this.state
    const {
      is_amc_lender,
      orderData,
      order,
      orderSubmissions,
      onHide,
      refreshOrder,
    } = this.props

    let requested = []

    if (
      (show_edit_appraisers && job_type === 'manually') ||
      (job_type === 'amc' && is_amc_lender)
    ) {
      requested = this.getUniqueRequestedAppraisers(selectedAppraisers)
    }

    let products = [...selected_products]

    // If we are in integration_mode, we only want to include the first product
    if (this.state.integrationMode) {
      products = [products[0]]
    }

    const finalized_additional_fees = additional_fees.filter(
      (fee) => fee.description && fee.amount,
    )

    const order_id = orderData.id

    const orderPayload = {
      order_id,
      fee: product_amount,
      initial_products: initialProducts,
      products,
      due_date: convertToUTCDate(due_date),
      priority,
      additional_fees: finalized_additional_fees,
      refresh: outOfAppraisersAutomatic,
      job_type,
      review_appraiser: review_appraiser_choice,
    }
    if (is_amc_lender && appraiser_due_date) {
      orderPayload.appraiser_due_date = convertToUTCDate(appraiser_due_date)
    }

    const isReview = job_type === 'review'
    if (!isReview) {
      // send the appraiser-related fields
      orderPayload.requested = requested
      orderPayload.order_request_method = order_request_method
      orderPayload.appraiser_acceptance_method = appraiser_acceptance_method
      orderPayload.offer_parameters = offer_parameters
      orderPayload.auto_accept_conditions = auto_accept_conditions
      orderPayload.consumer_payment_style = consumer_payment_style
      if (order_request_method === 'cascade') {
        // Send the cascade assignment limits
        orderPayload.max_cascade_assignments = maxCascadeAssignments
        orderPayload.max_cascade_non_pending_active_assignments = maxActiveNonPendingAssignments
      }
    }
    try {
      const updateResult = await order.put({
        desktop_return: true, ...orderPayload,
      })
      const isOrderUpdated = updateResult && 'id' in updateResult
      const hasFiles = pdfFile || xmlFile

      if (isOrderUpdated) {
        // Hide the Edit Order Modal
        onHide()
      }

      if (isReview && isOrderUpdated && hasFiles) {
        // gotta upload the submission files
        const createSubmissionBody = {
          order_id,
          pdf_file: pdfFile,
          xml_file: xmlFile,
        }

        await orderSubmissions.create(createSubmissionBody)
        onHide()
        refreshOrder()
      } else {
        onHide()
        refreshOrder()
      }
    } catch (e) {
      onHide()
    }
  }

  onSaveConsumer = async (values) => {
    const { addConsumer } = this.props
    const { email, homePhone, cellPhone, workPhone, firstName, lastName, role } = values
    const consumer = {
      email,
      role: role.toLowerCase(),
      home_phone: homePhone,
      cell_phone: cellPhone,
      work_phone: workPhone,
      full_name: firstName + ' ' + lastName,
      is_primary_contact: true,
    }
    await addConsumer.post({ consumer })
    this.afterSubmit(true)
  }

  onSubmit = async (needsPrimaryContact = false) => {
    const {
      selectedLoan,
      is_follow_up,
      startOrderRequests,
      originalOrder,
      onHide,
    } = this.props
    let {
      selectedAppraisers,
      additional_fees,
      loan,
      job_type,
      priority,
      product_amount,
      selected_products,
      selected_property,
      due_date,
      consumer_payment_style,
      order_request_method,
      appraiser_acceptance_method,
      offer_parameters,
      auto_accept_conditions,
      maxCascadeAssignments,
      maxActiveNonPendingAssignments,
    } = this.state

    // If it is a follow up order and user does not have perm to edit appraiser
    // Automatically set to same appraiser or different appraiser based on first product
    if (
      (is_follow_up &&
        !checkPermission('order_can_edit_assignment_on_create_follow_up')) ||
      (is_follow_up &&
        checkPermission('order_can_edit_assignment_on_create_follow_up') &&
        job_type === 'automatically')
    ) {
      if (originalOrder.job_type !== 'amc') {
        if (originalOrder.job_type === 'va' || job_type === 'va') {
          job_type = 'va'
        } else {
          job_type = 'automatically'
        }
      } else {
        job_type = 'automatically'
      }
    }

    due_date = due_date || moment()

    const products = [...selected_products]

    const finalized_additional_fees = additional_fees.filter(
      (fee) => fee.description && fee.amount,
    )

    // determine what information to send to backend
    const createOrderBody = {
      fee: product_amount,
      loan: selectedLoan ? selectedLoan.id : loan.id,
      property: selected_property !== 'keep' ? selected_property : undefined,
      due_date: convertToUTCDate(due_date),
      additional_fees: finalized_additional_fees,
      products,
      job_type,
      priority,
    }

    if (job_type !== 'review') {
      // include the fields needed for non-review orders
      const requested = this.getUniqueRequestedAppraisers(selectedAppraisers)

      createOrderBody.requested = requested
      createOrderBody.order_request_method = order_request_method
      createOrderBody.appraiser_acceptance_method = appraiser_acceptance_method
      createOrderBody.offer_parameters = offer_parameters
      createOrderBody.auto_accept_conditions = auto_accept_conditions
      createOrderBody.is_follow_up = is_follow_up
      createOrderBody.consumer_payment_style = consumer_payment_style
      createOrderBody.include_bad_vendors = true // Whenever we use ModalCreateOrderContainer, this bool will default to true. It'll modify the appraiser search_query so we will always include "bad vendors"
      if (order_request_method === 'cascade') {
        // Send the cascade assignment limits
        createOrderBody.max_cascade_assignments = maxCascadeAssignments
        createOrderBody.max_cascade_non_pending_active_assignments = maxActiveNonPendingAssignments
      }
    }

    if (startOrderRequests) {
      startOrderRequests()
    }

    // Only hide the create modal if we're proceeding with creation.
    onHide()

    this.createOrder(createOrderBody, job_type, needsPrimaryContact)
  }

  createEPCTransaction = async (order_id, loan_id) => {
    let isConnected = false

    try {
      host.connect()
      isConnected = true
    } catch (error) {}

    if (isConnected) {
      // First see if there's an open transaction we can use, instead of creating a new one.
      let response = {}
      try {
        response = await ellieMaeRequest(`epc/reusable-transaction-id/${loan_id}`)
      } catch (error) {
        datadogRum.addError(`Error fetching reusable transaction id for ${loan_id}. Error: ${JSON.stringify(error)}`)
      }

      let epcTransactionId = ''
      if (response.transaction_id) {
        // Reusable transaction id found. Use it.
        epcTransactionId = response.transaction_id
      } else {
        // Create a new transaction.
        let requestType = ELLIE_MAE_PRODUCT_REQUEST_TYPE.NEW_REQUEST_STANDARD

        // One way to identify alternate orders is fee <$200.
        // But some lenders start a standard/primary order with a fee of $1 and update it later.
        // Also, lenders sometimes cancel the standard/primary order and use the followup order action to create what is really the primary order.
        // So to identify alternate orders, we're checking both that it's a followup (since the initial order is never alternate) AND that the fee is <$200.
        if (this.props.is_follow_up && this.state.product_amount < 200) {
          requestType = ELLIE_MAE_PRODUCT_REQUEST_TYPE.NEW_REQUEST_ALTERNATE
        }

        const newTransactionRequest = {
          request: {
            type: requestType,
          },
        }

        let transactionObject = null
        let transactionData = null

        try {
          transactionObject = await host.getObject('transaction')
        } catch (error) {
          datadogRum.addError(`Error getting new EPC transaction for ${order_id}. Error: ${JSON.stringify(error)}`)
        }

        if (transactionObject) {
          try {
            transactionData = await transactionObject.create(newTransactionRequest)
            if (transactionData) {
              epcTransactionId = transactionData.id
            }
          } catch (error) {
            datadogRum.addError(`Error creating EPC transaction for ${order_id}. Error: ${JSON.stringify(error)}`)
          }
        }
      }

      if (epcTransactionId) {
        // Update order
        this.props.updateEPCTransaction.put({
          order_id: order_id,
          epc_transaction_id: epcTransactionId,
        })
      }
    }
  }

  createOrder = (createOrderBody, job_type, needsPrimaryContact = false) => {
    const {
      orderAdd,
      orderSubmissions,
    } = this.props

    const {
      pdfFile,
      xmlFile,
    } = this.state

    orderAdd.create(createOrderBody).then((createResult) => {
      const isReview = job_type === 'review'
      const isOrderCreated = createResult && 'id' in createResult
      const hasFiles = pdfFile || xmlFile

      // Need to create an EPC trancaction for Ellie Mae loan files. Only if loaded in Encompass.
      if (isOrderCreated && this.props.selectedLoan.ellie_loan_id && this.props.loanIdForEncompass) {
        this.createEPCTransaction(createResult.id, createOrderBody.loan)
      }

      if (isReview && isOrderCreated && hasFiles) {
        // upload the files for review orders

        const createSubmissionBody = {
          order_id: createResult.id,
          pdf_file: pdfFile,
          xml_file: xmlFile,
        }

        orderSubmissions.create(createSubmissionBody).then((_) => {
          this.afterSubmit()
        })
      } else {
        // if we don't need the review upload request, then refetch data now
        this.afterSubmit(createResult.id, needsPrimaryContact)
      }
    })
  }

  afterSubmit = (orderId, needsPrimaryContact = false) => {
    const { fullList, finishOrderRequests, refreshOrder, redirect, history } =
      this.props

    const afterLastRequest = () => {
      if (finishOrderRequests) {
        finishOrderRequests()
      }
    }

    if (redirect) {
      var url = '/orders/' + orderId
      history.push(url)
    }

    if (fullList) {
      fullList.fetch().then(afterLastRequest)
    } else if (refreshOrder) {
      refreshOrder()
    }
  }

  showAdditionalFees = () => {
    this.setState({ hasAdditionalFees: true })
  }

  clearSearchInput = () => {
    const { listAppraisers } = this.props
    const filterObject = this.buildFilterObject()
    filterObject.search = ''

    listAppraisers.filter(filterObject).then((response) =>
      this.setState({
        availableAppraisers: response.results,
        filteringAppraisers: false,
        searchInputText: '',
      }),
    )
  }

  // this event handler sets the correct filters from current state when user clicks out of filter modal
  handleClick = (e) => {
    // handle geofilter container
    if (this.state.popoversVisible.geoFilterPopover) {
      const geoFilterContainer = document.getElementById('geo-filter-container')
      if (
        e.target !== geoFilterContainer &&
        !geoFilterContainer.contains(e.target)
      ) {
        this.setState({
          selectedFilters: this.state.appliedFilters,
        })
      }
    }

    // handle specialization filter container
    if (this.state.popoversVisible.specializationFilterPopover) {
      const specializationContainer = document.getElementById(
        'specialization-filter-popover',
      )

      if (
        e.target !== specializationContainer &&
        !specializationContainer.contains(e.target)
      ) {
        this.setState({
          selectedFilters: this.state.appliedFilters,
        })
      }
    }

    // handle appraisal stats filter container
    if (this.state.popoversVisible.appraisalStatsPopover) {
      const appraisalStatsContainer = document.getElementById(
        'appraisal-stats-popover',
      )

      if (
        e.target !== appraisalStatsContainer &&
        !appraisalStatsContainer.contains(e.target)
      ) {
        this.setState({
          selectedFilters: this.state.appliedFilters,
        })
      }
    }

    // handle panel source filter container
    if (this.state.popoversVisible.appraisalPanelSourcePopover) {
      const panelSourceContainer = document.getElementById(
        'appraisal-panel-source-popover',
      )

      if (
        e.target !== panelSourceContainer &&
        !panelSourceContainer.contains(e.target)
      ) {
        this.setState({
          selectedFilters: this.state.appliedFilters,
        })
      }
    }

    // handle appraiser coverage filter container
    if (this.state.popoversVisible.appraiserCoveragePopover) {
      const appraiserCoverageContainer = document.getElementById(
        'appraiser-coverage-popover',
      )

      if (
        e.target !== appraiserCoverageContainer &&
        !appraiserCoverageContainer.contains(e.target)
      ) {
        this.setState({
          selectedFilters: this.state.appliedFilters,
        })
      }
    }
  }

  filterToggleHandler = (e) => {
    const name = e.target.name
    const checked = e.target.checked

    const { selectedFilters } = this.state

    // handle radiusSearchSelected toggle off
    if (name === 'radiusSearchSelected' && checked === false) {
      this.setState({
        selectedFilters: {
          ...selectedFilters,
          [name]: checked,
          radiusDistance: 0,
        },
      })
      // handle turnTimeSelected toggle off
    } else if (name === 'turnTimeSelected' && checked === false) {
      this.setState({
        selectedFilters: {
          ...selectedFilters,
          [name]: checked,
          turnTimeLower: 0,
          turnTimeUpper: 0,
        },
      })
      // handle rejectRateSelected toggle off
    } else if (name === 'rejectRateSelected' && checked === false) {
      this.setState({
        selectedFilters: {
          ...selectedFilters,
          [name]: checked,
          rejectRateLower: 0,
          rejectRateUpper: 0,
        },
      })
      // handle onTimeRateSelected toggle off
    } else if (name === 'onTimeRateSelected' && checked === false) {
      this.setState({
        selectedFilters: {
          ...selectedFilters,
          [name]: checked,
          onTimeRateLower: 0,
          onTimeRateUpper: 0,
        },
      })
      // all other filter toggle cases
    } else {
      this.setState({
        selectedFilters: {
          ...selectedFilters,
          [name]: checked,
        },
      })
    }
  }

  filterInputHandler = (e) => {
    const name = e.target.name
    const { selectedFilters } = this.state

    this.setState({
      selectedFilters: {
        ...selectedFilters,
        [name]: e.target.value,
      },
    })
  }

  addAppraiserHandler = (id, appraiser) => {
    const { selectedAppraisers } = this.state
    let alreadySelected = false
    const updatedAppraisers = [...selectedAppraisers]

    selectedAppraisers.forEach((a) => {
      if (a.id === appraiser.id) {
        alreadySelected = true
      }
    })

    if (!alreadySelected) {
      updatedAppraisers.push(appraiser)
    }

    this.setState(
      {
        selectedAppraisers: updatedAppraisers,
      },
      this.integrationUpdate,
    )
  }

  checkIfAlreadySelected = (appraiser) => {
    const { selectedAppraisers } = this.state
    let alreadySelected = false

    selectedAppraisers.forEach((a) => {
      if (a.id === appraiser.id) {
        alreadySelected = true
      }
    })

    if (alreadySelected) {
      return true
    } else {
      return false
    }
  }

  addIntegrationRushFee = () => {
    let {
      keyCounter,
      additional_fees,
      selected_products,
      priority,
      integrationMode,
    } = this.state
    const { integrationProducts } = this.props
    let newAdditionalFees = [...additional_fees]
    const products =
      integrationProducts.data && integrationProducts.data.data
        ? integrationProducts.data.data
        : []
    if (priority === 'Rush' && integrationMode) {
      newAdditionalFees = newAdditionalFees.filter(
        ({ isDefaultRushFee }) => !isDefaultRushFee,
      )
      const selectedIds = selected_products
        .filter((prod) => prod)
        .map(({ id }) => id)
      const existingAdditionalIds = additional_fees.map(
        ({ productId }) => productId,
      )
      products
        .filter((product) => selectedIds.includes(product.id))
        .forEach((product) => {
          if (
            product.external_rush_fee &&
            !existingAdditionalIds.includes(product.id)
          ) {
            keyCounter++
            const additional_fee = {
              amount: product.external_rush_fee,
              description: 'Product Rush Fee',
              key: keyCounter,
              productId: product.id,
              isProductRushFee: true,
            }
            if (additional_fees[0].amount == null) {
              newAdditionalFees = [additional_fee]
            } else {
              newAdditionalFees.push(additional_fee)
            }
          }
        })
      this.setState({
        hasAdditionalFees: newAdditionalFees.length > 0,
        additional_fees: newAdditionalFees,
      })
    }
  }

  // Checks if a reorder of the selected appraisers requires a new set of integration products or toggling of integration mode
  integrationUpdate = () => {
    const { integrationMode } = this.state
    const { selectedLoan, integrationProducts, products } = this.props
    const activeCompany = this.getActiveCompany()
    if (activeCompany && activeCompany.is_integration) {
      integrationProducts
        .filter({ company_id: activeCompany.company, loan_id: selectedLoan.id })
        .then((res) => {
          this.addIntegrationRushFee()
          this.setState({
            integrationMode: true,
            products: this.determineProducts(
              res.data,
              this.state.selected_products,
            ),
          })
        })
    } else if (integrationMode) {
      this.setState({
        integrationMode: false,
        products: this.determineProducts(
          products.data,
          this.state.selected_products,
        ),
      })
    }
  }

  defaultFollowUpAppraiser = () => {
    const {
      followUpAllocationData,
      is_follow_up,
    } = this.props
    if (this.state.already_assigned_followup_once === false) {
      // ensure we only calculate this once. doesn't seem ideal, but trying to match old behavior
      if (is_follow_up && followUpAllocationData && followUpAllocationData.follow_up_behavior === 'same appraiser' && followUpAllocationData.same_panelist) {
        // select the same appraiser provided by backend
        this.addAppraiserHandler(0, followUpAllocationData.same_panelist)
      }
      this.setState({ already_assigned_followup_once: true })
    }
  }

  removeAppraiserHandler = (id, appraiser) => {
    const { selectedAppraisers } = this.state
    const updatedAppraisers = selectedAppraisers.filter((a) => a.id !== id)

    this.setState(
      {
        selectedAppraisers: updatedAppraisers,
      },
      this.integrationUpdate,
    )
  }

  addAllAppraisersHandler = () => {
    const { listAppraisers } = this.props
    const selectedAppraisers = listAppraisers.data
    const updatedAppraisers = []

    selectedAppraisers.forEach((appraiser) => {
      if (
        !overrideVendorSelection(appraiser) &&
        !alwaysDisableVendorSelection(appraiser)
      ) {
        updatedAppraisers.push(appraiser)
      }
    })

    this.setState(
      {
        selectedAppraisers: updatedAppraisers,
      },
      this.integrationUpdate,
    )
  }

  removeAllAppraisersHandler = () => {
    const { products } = this.props
    this.setState({
      selectedAppraisers: [],
      integrationMode: false,
      products: this.determineProducts(
        products.data,
        this.state.selected_products,
      ),
    })
  }

  buildFilterObject = (duringMount = false) => {
    const { selectedLoan, orderData } = this.props

    let products = this.state.selected_products.map(
      (product) => product && product.id,
    )
    let isFhaLoan = null
    if (duringMount) {
      // we first use this method before state has been fully initialized
      // so we need to use the product off the order or loan file.
      if (orderData && orderData.products && orderData.products.length) {
        products = orderData.products.map((product) => product && product.id)
      } else if (
        selectedLoan &&
        selectedLoan.auto_products &&
        selectedLoan.auto_products.length
      ) {
        products = selectedLoan.auto_products.map(
          (product) => product && product.id,
        )
      }
      isFhaLoan = selectedLoan?.is_fha_loan
    }

    let order_id = null
    if (orderData) {
      order_id = orderData.id
    }

    const filterObject = {
      products,
      limit: 100000,
      active: true,
      brief: true,
      ordering: 'proximity',
      loan_id: selectedLoan.id,
      exclude: true,
      search: this.state.searchInputText,
      get_appraiser_metrics: true,
      reggora_network_panel:
        this.state.selectedFilters.panelSource === 'reggoraPanel',
      order_id: order_id,
      filter_values: JSON.stringify({
        filter_range: this.state.selectedFilters.radiusSearchSelected
          ? this.state.selectedFilters.radiusDistance
          : null,
        filter_specialization_fha: isFhaLoan || this.state.selectedFilters.specialization_fha,
        filter_license_certified: this.state.selectedFilters.licenseCertified,
        filter_license_certified_general:
          this.state.selectedFilters.licenseCertifiedGeneral,
        filter_license_certified_residential:
          this.state.selectedFilters.licenseCertifiedResidential,
        filter_turn_time_lower: this.state.selectedFilters.turnTimeSelected
          ? this.state.selectedFilters.turnTimeLower
          : null,
        filter_turn_time_upper: this.state.selectedFilters.turnTimeSelected
          ? this.state.selectedFilters.turnTimeUpper
          : null,
        filter_on_time_rate_lower: this.state.selectedFilters.onTimeRateSelected
          ? this.state.selectedFilters.onTimeRateLower
          : null,
        filter_on_time_rate_upper: this.state.selectedFilters.onTimeRateSelected
          ? this.state.selectedFilters.onTimeRateUpper
          : null,
        filter_revision_rate_lower: this.state.selectedFilters
          .rejectRateSelected
          ? this.state.selectedFilters.rejectRateLower
          : null,
        filter_revision_rate_upper: this.state.selectedFilters
          .rejectRateSelected
          ? this.state.selectedFilters.rejectRateUpper
          : null,
        filter_coverage_area:
          this.state.selectedFilters.appraiserCoverageApplied,
        filter_hide_declined_appraisers:
          this.state.selectedFilters.hideDeclinedAppraisersSelected,
      }),
      include_bad_vendors: true,
    }
    return filterObject
  }

  applyFilterHandler = () => {
    let {
      turnTimeSelected,
      turnTimeLower,
      turnTimeUpper,
      rejectRateSelected,
      rejectRateLower,
      rejectRateUpper,
      onTimeRateSelected,
      onTimeRateLower,
      onTimeRateUpper,
    } = this.state.selectedFilters

    // check and handle appraisal stats edge cases when one input is an empty string and the other is a value
    if (turnTimeSelected) {
      if (turnTimeLower === '') {
        turnTimeLower = 0
      }

      if (turnTimeUpper === '') {
        turnTimeUpper = 0
      }
    }

    if (rejectRateSelected) {
      if (rejectRateLower === '') {
        rejectRateLower = 0
      }

      if (rejectRateUpper === '') {
        rejectRateUpper = 0
      }
    }

    if (onTimeRateSelected) {
      if (onTimeRateLower === '') {
        onTimeRateLower = 0
      }

      if (onTimeRateUpper === '') {
        onTimeRateUpper = 0
      }
    }

    this.setState(
      {
        appliedFilters: {
          ...this.state.selectedFilters,
          turnTimeLower,
          turnTimeUpper,
          rejectRateLower,
          rejectRateUpper,
          onTimeRateLower,
          onTimeRateUpper,
          geoFiltersApplied: this.state.appliedFilters.geoFiltersApplied,
          specializationFiltersApplied:
            this.state.appliedFilters.specializationFiltersApplied,
          appraisalStatsApplied:
            this.state.appliedFilters.appraisalStatsApplied,
        },
        selectedFilters: {
          ...this.state.selectedFilters,
          turnTimeLower,
          turnTimeUpper,
          rejectRateLower,
          rejectRateUpper,
          onTimeRateLower,
          onTimeRateUpper,
        },
        popoversVisible: {
          geoFilterPopover: false,
          specializationFilterPopover: false,
          appraisalPanelSourcePopover: false,
          appraiserCoveragePopover: false,
          appraisalStatsPopover: false,
        },
      },
      this.applyFilterCallback,
    )
  }

  applyFilterCallback = () => {
    const { listAppraisers } = this.props
    const filterObject = this.buildFilterObject()

    listAppraisers.filter(filterObject).then((response) =>
      this.setState({
        availableAppraisers: response.results,
        filteringAppraisers: false,
      }),
    )
  }

  cancelFilterHandler = () => {
    this.setState({
      selectedFilters: this.state.appliedFilters,
      popoversVisible: {
        geoFilterPopover: false,
        specializationFilterPopover: false,
        appraisalPanelSourcePopover: false,
        appraiserCoveragePopover: false,
        appraisalStatsPopover: false,
      },
    })
  }

  resetFiltersHandler = () => {
    const { listAppraisers, selectedLoan } = this.props
    listAppraisers
      .filter({
        limit: 100000,
        active: true,
        brief: true,
        ordering: 'proximity',
        loan_id: selectedLoan.id,
        exclude: true,
        search: '',
        products: this.state.selected_products.map(
          (product) => product && product.id,
        ),
        get_appraiser_metrics: true,
        reggora_network_panel: false,
        filter_values: JSON.stringify({
          filter_range: null,
          filter_specialization_fha: false,
          filter_license_certified_general: false,
          filter_license_certified_residential: false,
          filter_turn_time_lower: null,
          filter_turn_time_upper: null,
          filter_on_time_rate_lower: null,
          filter_on_time_rate_upper: null,
          filter_revision_rate_lower: null,
          filter_revision_rate_upper: null,
        }),
      })
      .then((_) =>
        this.setState({
          availableAppraisers: listAppraisers.data,
          searchInputText: '',
          radiusSearchSelected: false,
          radiusDistance: 0,
          mapSearchSelected: false,
          mapOpen: false,
          selectedFilters: defaultSelectedFilters,
          appliedFilters: defaultAppliedFilters,
        }),
      )
  }

  onSearchInputChange = (e) => {
    this.setState({
      searchInputText: e.target.value,
    })
  }

  onSearchSubmission = () => {
    const { listAppraisers } = this.props
    const filterObject = this.buildFilterObject()
    listAppraisers.filter(filterObject).then((response) =>
      this.setState({
        availableAppraisers: response.results,
        filteringAppraisers: false,
      }),
    )
  }

  getIndexById = (id) => {
    const { selectedAppraisers } = this.state
    for (let i = 0; i < selectedAppraisers.length; i++) {
      if (selectedAppraisers[i].id === id) {
        return i
      }
    }
  }

  upArrowHandler = (id, appraiser) => {
    const { selectedAppraisers } = this.state
    const index = this.getIndexById(id)

    if (index === 0) {
      return
    }

    const tempReplacedAppraiser = { ...selectedAppraisers[index - 1] }

    const updatedSelectedAppraisers = selectedAppraisers.map((a, i) => {
      if (i === index - 1) {
        return appraiser
      } else {
        return a
      }
    })

    updatedSelectedAppraisers[index] = tempReplacedAppraiser

    this.setState(
      {
        selectedAppraisers: updatedSelectedAppraisers,
      },
      this.integrationUpdate,
    )
  }

  downArrowHandler = (id, appraiser) => {
    const { selectedAppraisers } = this.state
    const index = this.getIndexById(id)

    if (index === selectedAppraisers.length - 1) {
      return
    }

    const tempReplacedAppraiser = { ...selectedAppraisers[index + 1] }

    const updatedSelectedAppraisers = selectedAppraisers.map((a, i) => {
      if (i === index + 1) {
        return appraiser
      } else {
        return a
      }
    })

    updatedSelectedAppraisers[index] = tempReplacedAppraiser

    this.setState(
      {
        selectedAppraisers: updatedSelectedAppraisers,
      },
      this.integrationUpdate,
    )
  }

  toggleTab = (tab) => {
    if (tab === '2') {
      this.defaultFollowUpAppraiser()
    }
    this.setState({
      activeTab: tab,
    })
  }

  handleVisibleChange = (popoverName) => {
    this.setState({
      popoversVisible: {
        [popoverName]: !this.state.popoversVisible[[popoverName]],
      },
    })
  }

  // Closes popovers when user clicks outisde of popover
  closePopovers = () => {
    this.setState({
      popoversVisible: {
        priorityPopover: false,
        jobType: false,
      },
    })
  }

  sortAppraisers = (field, appraiserList, fetchedAppraisers) => {
    field = field.split('-')[1]

    if (appraiserList === 'selectedAppraisers') {
      const sortedSelectedAppraisers = this.state.selectedAppraisers.map(
        (appraiser) => appraiser,
      )
      const ascending = this.state.selectedAppraisersAscendingOrder[field]

      sortedSelectedAppraisers.sort(function(a, b) {
        if (ascending) {
          return (
            (a[field] === null) - (b[field] === null) ||
            +(a[field] > b[field]) ||
            -(a[field] < b[field])
          )
        } else {
          return (
            (a[field] === null) - (b[field] === null) ||
            -(a[field] > b[field]) ||
            +(a[field] < b[field])
          )
        }
      })

      this.setState({
        selectedAppraisers: sortedSelectedAppraisers,
        selectedAppraisersAscendingOrder: {
          ...this.state.selectedAppraisersAscendingOrder,
          [field]: !this.state.selectedAppraisersAscendingOrder[field],
        },
      })
    } else if (appraiserList === 'availableAppraisers') {
      const appraisersToSort = fetchedAppraisers ? [...fetchedAppraisers] : [...this.state.availableAppraisers]
      const sortedAppraisers = sortAvailableAppraisers(
        field, appraisersToSort, this.state.availableAppraisersAscendingOrder[field]
      )

      this.setState({
        availableAppraisers: sortedAppraisers,
        availableAppraisersAscendingOrder: {
          ...this.state.availableAppraisersAscendingOrder,
          [field]: !this.state.availableAppraisersAscendingOrder[field],
        },
      })
    }
  }

  // Change the action of onSubmit button in the modal based on (3 things):
  // Creating, Editing, or Reassigning
  getOnSubmitAction = () => {
    const { is_reassign_order } = this.props
    if (is_reassign_order) {
      return this.onReassignSubmit
    } else if (this.state.editing) {
      return this.onEditSubmit
    } else {
      return this.onSubmit
    }
  }

  onPDFChange = (file) => {
    this.setState({ pdfFile: file })
  }

  onXMLChange = (file) => {
    this.setState({ xmlFile: file })
  }

  onXMLRemove = () => {
    this.setState({ xmlFile: null })
  }

  render() {
    const {
      orderData,
      listAppraisers,
      should_show_consumer_payment_style_field,
      products,
      is_follow_up,
      role,
      is_using_amc,
      is_amc_lender,
      is_reassign_order,
      selectedLoan,
      use_integration_fee,
      onHide,
      loansWithoutStatus,
      reviewAppraiserOptions,
      originalOrder,
      orderFromStore,
      order,
    } = this.props

    const {
      editing,
      loadingProducts,
      productPermissions,
      job_type,
      selected_products,
      outOfAppraisersAutomatic,
      selectedAppraisers,
      xmlFile,
      pdfFile,
      products: productsState,
      auto_accept_conditions,
      product_amount,
      reggora_fee,
      additional_fees,
      priority,
      order_request_method,
      selectedFilters,
      activeTab,
      popoversVisible,
      due_date,
      appraiser_due_date,
      selected_property,
      review_appraiser_choice,
      outOfAppraisersManual,
      consumer_payment_style,
      hasAdditionalFees,
      searchInputText,
      appliedFilters,
      availableAppraisers,
      appraiser_acceptance_method,
      offer_parameters,
      maxCascadeAssignments,
      maxActiveNonPendingAssignments,
    } = this.state

    if (
      orderData &&
      ['succeeded'].includes(orderFromStore.remainingStatus) &&
      !editing &&
      !listAppraisers.isLoading &&
      products.data
    ) {
      this.setupEditing()
    }

    return (
      <Fragment>
        <Modal isOpen={this.state.isOrderCreationFailedAlertShowing}>
          <ModalBody>
            <ModalInformation
              onHide={() => this.setState({ isOrderCreationFailedAlertShowing: false })}
              title="Error Creating Order"
              body="An error prevented the order from being created. Please confirm that all order details are correct and submit the order again."
            />
          </ModalBody>
        </Modal>

        <ModalCreateOrder
          {...this.props}
          {...this.state}
          handleAutoAcceptInput={this.handleAutoAcceptInput}
          onCombinatorChange={this.onCombinatorChange}
          onSaveConsumer={this.onSaveConsumer}
          onSubmit={this.getOnSubmitAction()}
          onOrderDetailChange={this.onOrderDetailChange}
          showAdditionalFeesForm={this.showAdditionalFees}
          handleLoanChange={this.handleLoanChange}
          productSelected={this.productSelected}
          addProduct={this.addProduct}
          removeProduct={this.removeProduct}
          editProductAmount={this.editProductAmount}
          checkValidity={this.checkValidity}
          isMissingFields={this.isMissingFields}
          onAdditionalFeeRemove={this.onAdditionalFeeRemove}
          onAdditionalFeeAdd={this.onAdditionalFeeAdd}
          onAdditionalFeeChange={this.onAdditionalFeeChange}
          integrationMode={this.state.integrationMode}
          clearSearchInput={this.clearSearchInput}
          applyFilterHandler={this.applyFilterHandler}
          addAppraiserHandler={this.addAppraiserHandler}
          removeAppraiserHandler={this.removeAppraiserHandler}
          onSearchInputChange={this.onSearchInputChange}
          onSearchSubmission={this.onSearchSubmission}
          cancelFilterHandler={this.cancelFilterHandler}
          addAllAppraisersHandler={this.addAllAppraisersHandler}
          removeAllAppraisersHandler={this.removeAllAppraisersHandler}
          checkIfAlreadySelected={this.checkIfAlreadySelected}
          resetFiltersHandler={this.resetFiltersHandler}
          upArrowHandler={this.upArrowHandler}
          downArrowHandler={this.downArrowHandler}
          filterToggleHandler={this.filterToggleHandler}
          filterInputHandler={this.filterInputHandler}
          toggleTab={this.toggleTab}
          handleVisibleChange={this.handleVisibleChange}
          closePopovers={this.closePopovers}
          sortAppraisers={this.sortAppraisers}
          getIndexById={this.getIndexById}
          onPDFChange={this.onPDFChange}
          onPDFRemove={this.onPDFRemove}
          onXMLChange={this.onXMLChange}
          onXMLRemove={this.onXMLRemove}
          showPaymentOptions={should_show_consumer_payment_style_field}
          consumerPaymentOptions={
            this.state.consumerPaymentOptions
              ? this.state.consumerPaymentOptions
              : []
          }
          is_follow_up={is_follow_up}
          productPermissions={productPermissions}
          role={role}
          job_type={job_type}
          is_using_amc={is_using_amc}
          is_amc_lender={is_amc_lender}
          is_reassign_order={is_reassign_order}
          orderData={orderData}
          selected_products={selected_products}
          editing={editing}
          outOfAppraisersAutomatic={outOfAppraisersAutomatic}
          selectedAppraisers={selectedAppraisers}
          xmlFile={xmlFile}
          pdfFile={pdfFile}
          products={productsState}
          auto_accept_conditions={auto_accept_conditions}
          product_amount={product_amount}
          reggora_fee={reggora_fee}
          additional_fees={additional_fees}
          selectedLoan={selectedLoan}
          priority={priority}
          order_request_method={order_request_method}
          selectedFilters={selectedFilters}
          activeTab={activeTab}
          popoversVisible={popoversVisible}
          due_date={due_date}
          appraiser_due_date={appraiser_due_date}
          use_integration_fee={use_integration_fee}
          onHide={onHide}
          loansWithoutStatus={loansWithoutStatus}
          selected_property={selected_property}
          review_appraiser_choice={review_appraiser_choice}
          reviewAppraiserOptions={reviewAppraiserOptions}
          outOfAppraisersManual={outOfAppraisersManual}
          consumer_payment_style={consumer_payment_style}
          hasAdditionalFees={hasAdditionalFees}
          loadingProducts={loadingProducts}
          searchInputText={searchInputText}
          originalOrder={originalOrder}
          appliedFilters={appliedFilters}
          availableAppraisers={availableAppraisers}
          appraiser_acceptance_method={appraiser_acceptance_method}
          offer_parameters={offer_parameters}
          maxCascadeAssignments={maxCascadeAssignments}
          maxActiveNonPendingAssignments={maxActiveNonPendingAssignments}
        />

        {(listAppraisers.isLoading ||
          loadingProducts ||
          products.isLoading ||
          order.isLoading ||
          ['loading'].includes(orderFromStore.remainingStatus)) && <Loader />}
      </Fragment>
    )
  }
}

export function sortAvailableAppraisers(sortField, availableAppraisers, ascending) {
  /**
   * Given a sortfield, a list of appraisers, and a truthy/falsy asc flag, return a sorted list of appraisers.
   *
   * Bubbles null values to the bottom of the list in asc or desc.
   */
  const sortedAvailableAppraisers = [...availableAppraisers]

  // sort function that will always sort null values to end positions
  sortedAvailableAppraisers.sort((a, b) => {
    // asc/desc slightly messy since we can't bubble null to the end AND use .reverse() to invert a single sort
    if (a[sortField] === null && b[sortField] === null) {
      return 0
    } else if (a[sortField] === null) {
      return -1
    } else if (b[sortField] === null) {
      return -1
    } else if (a[sortField] > b[sortField]) {
      return ascending ? 1 : -1
    } else if (a[sortField] < b[sortField]) {
      return ascending ? -1 : 1
    } else {
      return 0
    }
  })

  return sortedAvailableAppraisers
}

function mapStateToProps(state, props) {
  return {
    default_due_date_internal: state.resource.user.data.lender.default_due_date_internal,
    default_due_date_internal_rush: state.resource.user.data.lender.default_due_date_internal_rush,
    default_order_request_method: state.resource.user.data.lender.default_order_request_method,
    use_default_rush_fee: state.resource.user.data.lender.use_default_rush_fee,
    default_rush_fee: state.resource.user.data.lender.default_rush_fee,
    max_cascade_assignments:
      props.orderData?.max_cascade_assignments ||
      state.resource.user.data.lender.max_cascade_assignments,
    max_cascade_non_pending_active_assignments:
      props.orderData?.max_cascade_non_pending_active_assignments ||
      state.resource.user.data.lender.max_cascade_non_pending_active_assignments,
    is_amc_lender: state.resource.user.data.lender.is_amc_lender,
    is_using_amc: state.resource.user.data.lender.is_using_amc,
    role: state.resource.user.data.role,
    calendar_type: state.resource.user.data.lender.calendar_type,
    should_show_consumer_payment_style_field: state.resource.user.data.lender.should_show_consumer_payment_style_field,
    use_integration_fee: state.resource.user.data.lender.use_integration_fee,
    use_default_proximity_allocation: state.resource.user.data.lender.use_default_proximity_allocation,
    default_proximity_allocation: state.resource.user.data.lender.default_proximity_allocation,
    orderFromStore: state.order,
    orderId: props.orderData?.id,
    id: props.selectedLoan.id,
    hasPrimaryContact: selectHasPrimaryContact(state),
    loanIdForEncompass: state.app.loanIdForEncompass,
    followUpAllocationData: state.order.followUpAllocationData,
    disableMultiProductSelection: state.resource.user.data.lender.disable_multi_product_selection_to_order,
  }
}

export default compose(
  withLDConsumer(),
  connect(mapStateToProps, null),
  connectResource({
    namespace: 'addConsumer',
    endpoint: 'loans/:id/consumers',
    prefetch: false,
    apiVersion: 2,
    successMessage: {
      POST: 'Your Contact has successfully been created.',
    },
  }),
  connectResource({
    namespace: 'orderAdd',
    endpoint: 'order/full/',
    prefetch: false,
    apiVersion: 2,
    successMessage: {
      POST: 'Your order has successfully been created. To view, please visit your orders tab.',
      PUT: 'The changes to your order have been saved.',
    },
  }),
  connectResource({
    namespace: 'fullSelectedLoan',
    endpoint: 'loans/:id?',
    async: true,
    list: false,
    refresh: true,
    apiVersion: 2,
  }),
  connectResource({
    namespace: 'fetchOverrideData',
    endpoint: 'fee/overrideData',
    list: true,
    prefetch: false,
    async: false,
    apiVersion: 2,
  }),
  connectResource({
    namespace: 'getDefaultDueDate',
    endpoint: 'fee/getDefaultDueDate',
    list: true,
    prefetch: false,
    async: false,
    apiVersion: 2,
  }),
  connectResource({
    namespace: 'consumerPaymentOptions',
    endpoint: 'order/payment-options',
    prefetch: false,
    list: true,
    apiVersion: 2,
  }),
  connectResource(
    {
      namespace: 'listAppraisers',
      endpoint: 'appraiser/',
      list: true,
      prefetch: false,
      apiVersion: 2,
      filters: {},
    }
  ),
  connectResource({
    namespace: 'reviewAppraiserOptions',
    async: false,
    endpoint: 'users/review',
    list: true,
    prefetch: false,
    refresh: false,
    apiVersion: 2,
  }),
  connectResource({
    namespace: 'fetchCompany',
    endpoint: 'appraiser/company',
    prefetch: false,
    apiVersion: 2,
    list: true,
    filters: { company_id: '' },
  }),
  connectResource({
    namespace: 'integrationProducts',
    endpoint: 'integrations/integrationProducts/companyProducts',
    prefetch: false,
    apiVersion: 2,
    list: true,
  }),
  connectResource({
    namespace: 'orderSubmissions',
    endpoint: 'order/submission',
    apiVersion: 2,
    prefetch: false,
    successMessage: {
      POST: 'The submission has been uploaded to this order.',
    },
  }),
  connectResource({
    namespace: 'order',
    endpoint: 'order/:orderId',
    prefetch: false,
    async: true,
    includeQueryParams: true,
    apiVersion: 2,
    successMessage: { PUT: 'Your order has been saved.' },
    filters: {
      get_follow_up_orders_incomplete: true,
      get_submissions_incomplete: true,
      get_minimal_data: true,
    },
  }),
  connectResource({
    namespace: 'products',
    endpoint: 'fee/full/',
    list: true,
    prefetch: false,
    refresh: true,
    async: true,
    apiVersion: 2,
    filters: {
      limit: 0,
      loan_id: '',
    },
  }),
  connectResource({
    namespace: 'getAllocationMode',
    endpoint: 'loans/defaultAllocationMode',
    list: true,
    prefetch: false,
    apiVersion: 2,
  }),
  connectResource({
    namespace: 'updateEPCTransaction',
    endpoint: 'order/update-epc-transaction',
    prefetch: false,
    async: true,
    includeQueryParams: true,
    apiVersion: 2,
  }),
  connect((state, props) => {
    return {
      default_allocation_mode: (props.flags && props.flags.loanPageOptimization
        ? state.resource.user.data.lender.default_allocation_mode : props.selectedLoan?.default_allocation_mode ||
        state.resource.user.data.lender.default_allocation_mode),
      id: props.selectedLoan.id,
      loanIdForEncompass: state.app.loanIdForEncompass,
    }
  }),
)(withRouter(ModalCreateOrderContainer))
