export const REPURCHASE_WARRANTY_STATUSES = {
  DISABLED: 'disabled',
  PENDING: 'pending',
  WARRANTY_SKIPPED: 'warranty_skipped',
  WARRANTY_GRANTED: 'warranty_granted',
  WARRANTY_DECLINED: 'warranty_declined',
}

export const REPURCHASE_WARRANTY_INVESTOR_STATUSES = {
  DISABLED: 'disabled',
  PENDING: 'pending',
  INVESTOR_GRANTED: 'investor_granted',
  INVESTOR_DECLINED: 'investor_declined',
}

export const REPURCHASE_WARRANTY_INVESTORS = {
  INVESTOR_FANNIE_MAE: 'fannie_mae',
  INVESTOR_FREDDIE_MAC: 'freddie_mac',
}
