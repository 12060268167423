import React from 'react'
import {
  checkCanViewOrderConversation,
  checkCanParticipateInOrderConversation,
} from '../../common/utils/helpers'
import OfferTab from './OfferTab'
import AmpNotes from './AmpNotes'

const AppraiserRequestTab = (props) => {
  const {
    orderData,
    using_amc_lender,
    currentUserID,
    markReadMessages,
    is_amc_lender,
    lenderData,
    refreshOrder,
  } = props

  let hasOfferConversation = false
  let offerConversationParticipation = false
  const broadcastOrCascade = orderData.order_request_method === 'broadcast' || orderData.order_request_method === 'cascade'

  if (
    orderData.assignment_list &&
    orderData.assignment_list.length > 0 &&
    ((orderData.assignment_list[0].offers &&
      orderData.assignment_list[0].offers.length > 0) ||
      broadcastOrCascade)
  ) {
    if (checkCanViewOrderConversation(orderData)) {
      hasOfferConversation = true

      if (checkCanParticipateInOrderConversation(orderData)) {
        offerConversationParticipation = true
      }
    }
  }

  const requestedCompanyIsAcceptedCompany = (request) => {
    let isAcceptedCompany = false

    if (request.status === 'company_accepted') {
      isAcceptedCompany = true
    }
    return isAcceptedCompany
  }
  const renderOffers = () => {
    const offers = []

    if (orderData.accepted) {
      let assignments = orderData.assignment_list
      // Use entire assignment_list for AMP to show all offers
      if (is_amc_lender) {
        assignments = orderData.full_assignment_list.filter(request => (request.offers || []).length > 0)
      }

      // IF an order is accepted, we need to render the offers with the FIRST OFFER being the accepted one
      // AND we need to grey out the rejected offers
      assignments.forEach((request, index) => {
        const isAcceptedCompany = requestedCompanyIsAcceptedCompany(request)

        const offerComponent = (
          <OfferTab
            key={request.id}
            request={request}
            index={index}
            orderData={orderData}
            hasOfferConversation={hasOfferConversation}
            conversationParticipation={offerConversationParticipation}
            isAcceptedCompany={isAcceptedCompany}
            currentUserID={currentUserID}
            markReadMessages={markReadMessages}
            usingAmcLender={using_amc_lender}
            is_amc_lender={is_amc_lender}
            lenderData={lenderData}
            refreshOrder={refreshOrder}
          />
        )

        if (isAcceptedCompany) {
          offers.unshift(offerComponent)
        } else {
          offers.push(offerComponent)
        }
      })
    } else {
      let assignments = orderData.assignment_list
      if (using_amc_lender) {
        assignments = orderData.assignment_list.filter(request => {
          return (request.offers || []).filter(offer => offer.is_pending_client_lender).length > 0
        })
      }
      // render the offers in the order that they appear from the list we're getting from the backend
      assignments.forEach((request, index) => {
        const offerComponent = (
          <OfferTab
            key={request.id}
            request={request}
            index={index}
            orderData={orderData}
            hasOfferConversation={hasOfferConversation}
            conversationParticipation={offerConversationParticipation}
            isAcceptedCompany={false}
            currentUserID={currentUserID}
            markReadMessages={markReadMessages}
            usingAmcLender={using_amc_lender}
            is_amc_lender={is_amc_lender}
            lenderData={lenderData}
            refreshOrder={refreshOrder}
          />
        )
        offers.push(offerComponent)
      })
    }
    return offers
  }

  // Always display for amc lender
  if (is_amc_lender || using_amc_lender) {
    const offers = renderOffers()
    if (offers.length > 0) {
      return (
        <>
          {is_amc_lender && <AmpNotes />}
          {offers}
        </>
      )
    }
    return (
      <div>
        {is_amc_lender && <AmpNotes />}
        <div>This order has not received any offers yet</div>
      </div>
    )
  }

  return (
    <>
      {is_amc_lender && <AmpNotes />}
      {!using_amc_lender &&
      orderData &&
      orderData.assignment_list &&
      orderData.assignment_list.length > 0 &&
      ((orderData.assignment_list[0].offers &&
        orderData.assignment_list[0].offers.length > 0) ||
        broadcastOrCascade)
        ? renderOffers()
        : 'This order has not received any offers yet'}
    </>
  )
}

export default AppraiserRequestTab
